*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  color: inherit;
  font-family: Roboto, serif;
  line-height: 1.5;
}

body {
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto/Roboto-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}